import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';

import './global.scss';

import * as config from './config.json';
import { Authenticator } from '@/authentication';

Amplify.configure(config);

// This solution did not work when hosting with cloudfront - I have commented it out
// for deployment of AUI v2.0 9/19/2022 but leaving it in comments in the hopes we can
// resolve the browser refresh issue in a near, future deployment.
// made updates to support browser refresh with react routing on aws-static webpage hosting
// details here: https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
//const history = createBrowserHistory();
//const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
//if (path) {
//  history.replace(path);
//}

ReactDOM.render(
  <React.StrictMode>
    <Authenticator />
  </React.StrictMode>,
  document.getElementById('root')
);
