import * as React from 'react';
import { useContext } from 'react';
import { Button, Header } from '@/components';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { inIframe, useIframeMessageHandler } from '@/utils';

export const Home = () => {
  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });
  const navigate = useNavigate();
  const {
    imageUrl,
    asset_list: { welcome_foreground_image }
  } = useContext(AssetsContext);

  useIframeMessageHandler();

  return (
    <div className={styles.home}>
      <Header />
      {!isInIframe && (
        <div className={styles.logoWrap}>
          <img
            className={styles.logo}
            src={`${imageUrl}/${welcome_foreground_image}`}
          />
        </div>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={() => navigate('/createCustomer')}
          className={styles.button}
          text="Sign up"
          size="large"
        />
        <Button
          onClick={() => navigate('/search')}
          className={styles.button}
          text="Search customer"
          size="large"
        />
      </div>
    </div>
  );
};
