import { Loader as AWSLoader } from '@aws-amplify/ui-react';
import * as React from 'react';
import { useStyles } from './styles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <div className={styles.loaderWrapper}>
      <AWSLoader size="large" />
    </div>
  );
};
