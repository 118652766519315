import { ContentBlock, Typography, Button, Header } from '@/components';
import { AppContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext, FC } from 'react';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  link: {
    color: 'blue'
  },
  retryButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  retryButton: {
    marginTop: 20,
    maxWidth: 200
  }
});

interface ErrorProps {
  emailConfigurationError: boolean;
  email: string | null;
}

export const Error: FC<ErrorProps> = ({ emailConfigurationError, email }) => {
  const { id } = useContext(AppContext);
  const styles = useStyles();

  const retryHandler = () => {
    window.location.reload();
  };

  return (
    <>
      <Header />
      <ContentBlock>
        <Typography component="h3">
          Your company information is not correctly configured. Please contact
          Size Stream Support{' '}
          <a href="mailto:support@sizestream.com" className={styles.link}>
            support@sizestream.com
          </a>{' '}
          with your company identifier {id}.
          {emailConfigurationError && email && (
            <>
              <br />
              <br />
              No store has been assigned to the email {email}.
            </>
          )}
        </Typography>
        <div className={styles.retryButtonWrap}>
          <Button
            buttonType="dark"
            text="Retry"
            onClick={retryHandler}
            className={styles.retryButton}
          />
        </div>
      </ContentBlock>
    </>
  );
};
