const oneCmInFt = 0.032808399;
export const onePnInKg = 2.2046226218;
const oneCmInInch = 0.393701;
const inchesInFt = 12;

export const roundToTwoDecimalPlaces = (value: number): number => {
  if (!value) return null;

  const numberValue = Number(value);

  return parseFloat(numberValue.toFixed(2));
};

export const covertCmToFt = (value: number): [number, number] => {
  if (value === null) return [null, null];

  if (value < 122) {
    return [4, 0];
  } else if (value > 241) {
    return [7, 11];
  }
  const ftValue = Math.floor(value * oneCmInFt);
  const inchesValue = (value - ftValue / oneCmInFt) * oneCmInInch;
  return [ftValue, inchesValue];
};

export const convetFtToCm = (
  feet: number | null | undefined,
  inch: number | null | undefined
): number => {
  // if either feet or inch are undefined (migrated accounts)
  // or if feet AND inch are falsey (null, 0, undefined)
  // have to handle case where inch can be 0
  if (feet === undefined || inch === undefined || (!feet && !inch)) return null;

  return feet / oneCmInFt + inch / oneCmInInch;
};

export const converFtToInches = (feet: number, inch: number): number => {
  if (feet === null && inch === null) return null;

  return roundToTwoDecimalPlaces(feet * inchesInFt + Number(inch));
};

export const convertInchesToFt = (
  value: number | null
): { feet: number; inch: number } => {
  if (!value) return { feet: null, inch: null };
  return { feet: ~~(value / inchesInFt), inch: value % 12 };
};
