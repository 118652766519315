import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  wrapper: ({ isInIframe }: { isInIframe: boolean }) => ({
    width: '100%',
    '@media (max-width: 576px)': {
      paddingTop: 60
    },
    ...(isInIframe
      ? {
          paddingTop: '0 !important'
        }
      : {})
  }),
  contentBlock: ({ isInIframe }: { isInIframe: boolean }) => ({
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: 850,
    backgroundColor: theme.colors.white,
    borderRadius: 40,
    padding: '20px 30px',
    '@media (max-width: 768px)': {
      maxWidth: 540,
      borderRadius: 15,
      padding: '30px 25px'
    },
    '@media (max-width: 576px)': {
      maxWidth: 370,
      borderRadius: 15,
      padding: '30px 25px'
    },
    ...(isInIframe
      ? {
          padding: '0 !important',
          maxWidth: 'auto !important'
        }
      : {})
  }),
  content: ({ isInIframe }: { isInIframe: boolean }) => ({
    position: 'relative',
    '@media (max-width: 768px)': {
      maxHeight: 700,
      overflow: 'auto'
    },
    '@media (max-width: 576px)': {
      maxHeight: 500,
      overflow: 'auto'
    },
    ...(isInIframe
      ? {
          maxHeight: 'initial !important'
        }
      : {})
  }),
  backButton: {
    position: 'absolute',
    top: 20,
    right: 40,
    cursor: 'pointer',
    width: 20,
    height: 20,
    zIndex: 100,
    '& span, &:before': {
      position: 'absolute',
      top: 9,
      height: 2,
      width: '100%',
      display: 'inline-block',
      background: theme.colors.black
    },
    '&:before': {
      content: '""',
      transform: 'rotate(45deg)'
    },
    '& span': {
      transform: 'rotate(-45deg)'
    }
  }
}));
