import { useEffect, useRef, useState } from 'react';
import { convetFtToCm, covertCmToFt, onePnInKg } from './calculations.helper';

export const useCreateUpdateCustomerForm = ({ setFieldValue, values }) => {
  const ftInputRef = useRef<HTMLInputElement>();
  const inInputRef = useRef<HTMLInputElement>();
  const cmInputRef = useRef<HTMLInputElement>();
  const weightInputRef = useRef<HTMLInputElement>();
  const ageInputRef = useRef<HTMLInputElement>();
  const emailInputRef = useRef<HTMLInputElement>();

  const [wasFirstRender, setWasFirstRender] = useState(false);

  useEffect(() => {
    setFieldValue('height', convetFtToCm(values.feet, values.inch));
  }, [values.feet, values.inch]);

  useEffect(() => {
    if (wasFirstRender) {
      const heightMeasure = window.localStorage.getItem('heightMeasure');

      if (heightMeasure !== values.heightMeasure) {
        window.localStorage.setItem('heightMeasure', values.heightMeasure);
      }

      if (values.heightMeasure === 'fnIn') {
        const [feet, inch] = covertCmToFt(values.height);
        setFieldValue('feet', feet);
        setFieldValue('inch', inch);

        ftInputRef.current.inputMode = 'numeric';
        inInputRef.current.inputMode = 'numeric';
      } else {
        cmInputRef.current.inputMode = 'numeric';
        setFieldValue('height', convetFtToCm(values.feet, values.inch));
      }
    }
  }, [values.heightMeasure]);

  useEffect(() => {
    if (wasFirstRender) {
      const weightMeasure = window.localStorage.getItem('weightMeasure');

      if (weightMeasure !== values.weightMeasure) {
        window.localStorage.setItem('weightMeasure', values.weightMeasure);
      }

      if (values.weightMeasure === 'lbs') {
        setFieldValue(
          'weight',
          values.weight === null ? null : values.weight * onePnInKg
        );
      } else {
        setFieldValue(
          'weight',
          values.weight === null ? null : values.weight / onePnInKg
        );
      }
    }
  }, [values.weightMeasure]);

  useEffect(() => {
    const heightMeasure = window.localStorage.getItem('heightMeasure');

    if (heightMeasure) {
      setFieldValue('heightMeasure', heightMeasure);
    }

    const weightMeasure = window.localStorage.getItem('weightMeasure');

    if (weightMeasure) {
      setFieldValue('weightMeasure', weightMeasure);
    }

    ftInputRef.current.inputMode = 'numeric';
    inInputRef.current.inputMode = 'numeric';
    weightInputRef.current.inputMode = 'numeric';
    ageInputRef.current.inputMode = 'numeric';
    if (emailInputRef.current) {
      emailInputRef.current.inputMode = 'email';
    }
    setWasFirstRender(true);
  }, []);

  return {
    ftInputRef,
    inInputRef,
    cmInputRef,
    weightInputRef,
    ageInputRef,
    emailInputRef
  };
};
