import {
  AssetsContext,
  InIframeContext
} from '@/GlobalProvider/GlobalProvider';
import { getUserScans } from '@/api';
import { useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { getSuccessfulScan } from './get-successful-scan';

const getUserState = async (currentCustomer, previousScanId, eventId) => {
  let scanId = previousScanId;
  if (!previousScanId && currentCustomer?.id) {
    const _scanId = await getScanId(currentCustomer.id);
    scanId = _scanId;
  }

  return {
    firstName: currentCustomer?.firstName || null,
    lastName: currentCustomer?.lastName || null,
    userId: currentCustomer?.id || null,
    email: currentCustomer?.email || null,
    scanId: scanId || null,
    eventId: eventId || null
  };
};

const getScanId = async (customerId) => {
  if (!customerId) return null;

  const scansData = await getUserScans(customerId);
  const successfulScanData = getSuccessfulScan(scansData);

  return successfulScanData?.id;
};

const getEventId = async (configurator_eventid_lookup_list) => {
  const user = await Auth.currentUserInfo();
  const email = user.attributes.email;
  const eventId = configurator_eventid_lookup_list[email];

  return eventId;
};

export const useIframeMessageHandler = (
  currentCustomer?: any,
  previousScanId?: string
) => {
  const inIframe = useContext(InIframeContext);
  const {
    behavior_list: { configurator_eventid_lookup_list }
  } = useContext(AssetsContext);

  useEffect(() => {
    (async () => {
      if (inIframe) {
        const eventId = await getEventId(configurator_eventid_lookup_list);

        const userState = await getUserState(
          currentCustomer,
          previousScanId,
          eventId
        );
        window.parent.postMessage(userState, '*');
      }
    })();
  }, [currentCustomer, previousScanId, configurator_eventid_lookup_list]);
};
