import { ScanStatus } from '@/constants';
import API from '@aws-amplify/api';

const THREE_D_ID_API = {
  BASE_URI: 'three-d-id-api'
};

const assetsUrl = process.env.REACT_APP_ASSETS_URL;

export const getAssetsFile = async (id: string) => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';

  try {
    //const res = await fetch(`${assetsUrl}/${id}/${id}.json`);
    const res = await fetch(`${assetsUrl}/${id}/${id}.json?random=${random}`);
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};

export const getDefaultAssetsFile = async () => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';
  try {
    //const res = await fetch(`${assetsUrl}/default/default.json`);
    const res = await fetch(
      `${assetsUrl}/default/default.json?random=${random}`
    );
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};

export const getApps = async () => {
  const path = '/apps';
  return await API.get(THREE_D_ID_API.BASE_URI, path, null);
};

export const getAppDateQueries = async (appId: string) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getDerivedData = async (scanId: string, query: string) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getUsers = async (
  hasPagination?: boolean,
  startAfter?: string
) => {
  try {
    const path = startAfter ? `/users?startAfter=${startAfter}` : '/users';
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (e) {
    console.error(e);
  }
};

export const createCustomer = async (user: any, appId: string) => {
  const path = '/users';
  const myInit = {
    body: {
      sourceApp: appId,
      email: user.email !== undefined ? user.email : '',
      gender: user.gender !== undefined ? user.gender : '',
      phone: user.phone !== undefined ? user.phone : '',
      dateOfBirth: user.dateOfBirth !== undefined ? user.dateOfBirth : '',
      firstName: user.firstName !== undefined ? user.firstName : '',
      lastName: user.lastName !== undefined ? user.lastName : '',
      height: user.height !== undefined ? user.height : '',
      weight: user.weight !== undefined ? user.weight : ''
    }
  };
  return await API.post(THREE_D_ID_API.BASE_URI, path, myInit);
};

export const provisionCustomer = async (
  userId: string,
  appId: string,
  userHandle: string
) => {
  try {
    const path = `/apps/${appId}/users/${userHandle}`;
    const myInit = {
      body: { userId }
    };
    return await API.put(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    return error;
  }
};

export const getCustomerProvision = async (
  appId: string,
  userHandle: string
) => {
  try {
    const path = `/apps/${appId}/users/${userHandle}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, {});
  } catch (e) {
    const error = JSON.stringify(e.message);

    if (error === '"Network Error"') {
      throw e;
    }
    return e;
  }
};

export const getAgreements = async (appID: string) => {
  try {
    const path = `/agreements?entity=${appID}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, {});
  } catch (error) {
    return error;
  }
};

interface AgreementType {
  id: string;
  date: string;
  terms: string;
  name: string;
  entity: string;
}

export const addUserAgreements = async (
  userID: string,
  agreements: Array<AgreementType>,
  signature: string
) => {
  try {
    const path = `/users/${userID}/multipleAgreements`;
    const myInit = {
      body: agreements.map((agreement) => ({
        agreement: agreement.id,
        date: agreement.date,
        signature,
        status: 'Signed'
      }))
    };
    return await API.post(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    return error;
  }
};

export const getUser = async (userId) => {
  try {
    const path = `/users/${userId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return ex.response;
  }
};

export const patchUser = async (user) => {
  const path = `/users/${user.id}`;
  const myInit = {
    body: user
  };
  return await API.patch(THREE_D_ID_API.BASE_URI, path, myInit);
};

type Scan = {
  app: string;
  multiscanNum: number;
  pipelineMetadata: any;
  status: ScanStatus;
  datePartition: string;
  date: string;
  pipeline: string;
  data: {
    mesh: string;
    measures: string;
    blob: string;
  };
  parameters: {
    weight: number;
    gender: string;
    age: number;
    height: number;
  };
  sourceMetadata: any;
  id: string;
  type: string;
};

type GetUserScans = (userId: string) => Promise<Array<Scan>>;

export const getUserScans: GetUserScans = async (userId) => {
  try {
    const path = `/users/${userId}/scans`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getCoreMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/core-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/app-measures/3`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getScanImage = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/body-mesh?format=image/png`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getUserCountryCode = async () => {
  const res = await fetch(`${process.env.REACT_APP_IPAPI_URL}/country/`);
  const countryCode = await res.text();

  return countryCode.toLowerCase();
};

export const getUserIP = async () => {
  const res = await fetch(`${process.env.REACT_APP_IPAPI_URL}/ip/`);
  const IP = await res.text();

  return IP;
};
