import { createUseStyles } from 'react-jss';

interface StylesProps {
  show_avatar: boolean;
}

export const useStyles = createUseStyles(({ colors }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
    '@media (max-width: 576px)': {
      flexDirection: 'column'
    }
  },
  title: {
    marginBottom: 20
  },
  image: {
    width: 320,
    height: 320,
    marginBottom: 50,
    '& img': {
      width: '100%',
      height: '100%'
    },
    '@media (max-width: 576px)': {
      width: '100%',
      height: 'auto'
    }
  },
  customImage: {
    '& img': {
      width: '100%',
      objectFit: 'contain'
    }
  },
  backButton: {
    width: '200px !important',
    '@media (max-width: 576px)': {
      width: '100% !important',
      marginBottom: 20
    }
  },
  removeButton: {
    width: '150px !important',
    '& span': {
      display: 'flex',
      alignItems: 'center'
    },
    '& svg': {
      width: 18,
      height: 18,
      marginLeft: 5,
      fill: colors.error
    },
    '&:hover': {
      '& svg': {
        fill: colors.white
      }
    },
    '@media (max-width: 576px)': {
      width: '100% !important'
    }
  },
  tableWrap: ({ show_avatar }: StylesProps) => ({
    maxHeight: show_avatar ? 400 : 350,
    overflow: 'auto',
    boxShadow: '0px 5px 15px 5px rgba(0,0,0,0.35)',
    '@media (max-width: 576px)': {
      boxShadow: 'none'
    }
  }),
  footer: {
    paddingTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 576px)': {
      flexDirection: 'column'
    }
  },
  userInfo: {
    marginBottom: 20
  }
}));
