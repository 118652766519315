import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { inIframe } from '@/utils';

interface ContentBlockProps {
  children: React.ReactNode;
  hasBackButton?: boolean;
  backHandler?: () => void;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  children,
  hasBackButton,
  backHandler
}) => {
  const navigate = useNavigate();

  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });

  const onBackButtonPress = backHandler || (() => navigate('/'));

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBlock}>
        {hasBackButton && (
          <div className={styles.backButton} onClick={onBackButtonPress}>
            <span />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
