import * as React from 'react';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '@/regex';
import { Button, TextInput, PhoneInputField } from '@/components';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import parsePhoneNumber from 'libphonenumber-js';

const getSchema = ({ countryCode, dialCode }, errorMessage) =>
  Yup.object().shape(
    {
      email: Yup.string()
        .when('phone', {
          is: (phoneNumber) => !phoneNumber,
          then: Yup.string().required(errorMessage),
          otherwise: Yup.string()
        })
        .matches(EMAIL_REGEX, 'Enter valid email address'),
      phone: Yup.string().when('email', {
        is: (email) => !email,
        then: Yup.string()
          .required(errorMessage)
          .test('isCorrectNumber', `Phone number isn't valid`, (val) => {
            if (!countryCode) return true;

            const phone = parsePhoneNumber(val, countryCode.toUpperCase());

            return phone.isValid();
          }),
        otherwise: Yup.string().test(
          'isCorrectNumber',
          `Phone number isn't valid`,
          (val) => {
            if (!countryCode || dialCode === val) return true;

            const phone = parsePhoneNumber(val, countryCode.toUpperCase());

            return phone.isValid();
          }
        )
      })
    },
    [['phone', 'email']]
  );

const initialValues = {
  email: '',
  phone: ''
};

export type SearchCustomerData = {
  email: string;
  phone: string;
};

interface SearchCustomerFormProps {
  onSubmit: (params: SearchCustomerData) => void;
  isLoading: boolean;
}
export const SearchForm: React.FC<SearchCustomerFormProps> = ({
  onSubmit,
  isLoading
}) => {
  const styles = useStyles();
  const [countryCode, setCountryCode] = useState<{
    countryCode: string;
    dialCode: string;
  }>({ countryCode: '', dialCode: '' });
  const emailInputRef = useRef<HTMLInputElement>();
  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);

  const showPhoneInput = customer_account_handle !== 'email_only';
  const showEmailInput = customer_account_handle !== 'phone_only';

  const errorMessage =
    customer_account_handle === 'email_only'
      ? 'Email address is required'
      : customer_account_handle === 'phone_only'
      ? 'Phone number is required'
      : 'Phone number or email address is required';

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.inputMode = 'email';
    }
  }, []);

  const submitHandler = useCallback((value: any) => {
    onSubmit({
      ...value,
      phone: value.phone === countryCode.dialCode ? '' : value.phone
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getSchema(countryCode, errorMessage)}
      onSubmit={submitHandler}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInputs}>
              {showPhoneInput && (
                <div className={styles.input}>
                  <PhoneInputField
                    value={values.phone}
                    error={errors.phone as string}
                    touched={touched.phone as boolean}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setCountryCode={setCountryCode}
                  />
                </div>
              )}
              {showEmailInput && (
                <div className={styles.input}>
                  <TextInput
                    ref={emailInputRef}
                    label="Email address"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    value={values.email.toLowerCase()}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                      handleChange(e);
                    }}
                    hasError={touched.email && !!errors.email}
                    errorMessage={errors.email as string}
                  />
                </div>
              )}
            </div>
            <div className={styles.buttonBlock}>
              <Button
                type="submit"
                text="Search"
                buttonType="secondary"
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
