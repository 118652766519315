import * as React from 'react';
import { useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, TextInput } from '@/components';
import { useStyles } from '../SearchForm/styles';

const schema = Yup.object().shape({
  id: Yup.string().required('Subject ID is required')
});

const initialValues = {
  id: ''
};

interface SearchCustomerFormProps {
  onSubmit: (id: string) => void;
  isLoading: boolean;
}
export const SearchByIdForm: React.FC<SearchCustomerFormProps> = ({
  onSubmit,
  isLoading
}) => {
  const styles = useStyles();

  const submitHandler = useCallback((value: any) => {
    onSubmit(value.id);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submitHandler}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInputs}>
              <div className={styles.input}>
                <TextInput
                  label="Subject ID"
                  type="text"
                  name="id"
                  placeholder="Enter Subject ID"
                  value={values.id.toLowerCase()}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '');
                    handleChange(e);
                  }}
                  hasError={touched.id && !!errors.id}
                  errorMessage={errors.id as string}
                />
              </div>
            </div>
            <div className={styles.buttonBlock}>
              <Button
                type="submit"
                text="Search"
                buttonType="secondary"
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
