import parsePhoneNumber from 'libphonenumber-js';

export const formatPhoneNumber = (phone: string) => {
  // if phone contain + sign, remove it
  if (phone.includes('+')) {
    phone = phone.replace('+', '');
  }
  const phoneNumber = parsePhoneNumber('+' + phone);

  return phoneNumber.formatInternational();
};
