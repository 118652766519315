import { ContentBlock, Typography, Header } from '@/components';
import * as React from 'react';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  text: {
    padding: '20px 0'
  }
});

export const BrowserError = () => {
  const styles = useStyles();

  return (
    <>
      <Header />
      <ContentBlock>
        <Typography component="h3" className={styles.text}>
          Sorry, this browser is not supported. Please open with Chrome or
          Safari.
        </Typography>
      </ContentBlock>
    </>
  );
};
