import * as React from 'react';
import {
  Button as AwsButton,
  ButtonProps as AwsButtonProps
} from '@aws-amplify/ui-react';
import * as classnames from 'classnames';

interface ButtonProps {
  text: React.ReactElement | string;
  buttonType?: 'primary' | 'secondary' | 'dark' | 'error';
}

export const Button: React.FC<
  Omit<AwsButtonProps, 'variation'> & ButtonProps
> = ({ text, className, buttonType = 'primary', isDisabled, ...props }) => {
  return (
    <AwsButton
      className={classnames(
        `custom-button custom-button-${isDisabled ? 'disable' : buttonType}`,
        className
      )}
      loadingText="Loading"
      isDisabled={isDisabled}
      {...props}
    >
      {text}
    </AwsButton>
  );
};
