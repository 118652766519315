import * as React from 'react';
import { FC, useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { AssetsContext } from './GlobalProvider';

export const useStyles = createUseStyles((theme) => ({
  videoContainer: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    background: '#000',
    zIndex: 5000,
    overflow: 'hidden'
  },
  video: {
    width: '100%',
    height: '100%'
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: '50px',
    height: '50px',
    zIndex: 5001,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: 4,
      left: 0,
      background: theme.colors.white,
      borderRadius: '50px'
    },
    '&:before': {
      transform: 'rotate(45deg)'
    },
    '&:after': {
      transform: 'rotate(-45deg)'
    }
  }
}));

export const VideoContext = React.createContext(null);

interface VideoProps {
  closeHandler: () => void;
}

const Video: FC<VideoProps> = ({ closeHandler }) => {
  const styles = useStyles();
  const { imageUrl, asset_list } = useContext(AssetsContext);

  return (
    <div className={styles.videoContainer}>
      <button onClick={closeHandler} className={styles.closeButton}>
        <span />
      </button>
      <video controls autoPlay onEnded={closeHandler} className={styles.video}>
        <source
          src={`${imageUrl}/${asset_list.prescan_video}`}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export const VideoProvider = ({ children }) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <VideoContext.Provider value={{ setShowVideo }}>
      {showVideo && <Video closeHandler={() => setShowVideo(false)} />}
      {children}
    </VideoContext.Provider>
  );
};
