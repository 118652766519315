import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  header: {
    textAlign: 'center',
    marginBottom: 40,
    '@media (max-width: 576px)': {
      marginBottom: 60
    }
  },
  title: {
    marginBottom: 15
  }
});
